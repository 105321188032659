<template>
    <b-card no-body header-bg-variant="primary" header="Comunicazioni" header-text-variant="white" header-tag="h5"
        border-variant="primary">
        <b-card-body class="p-0">
            <div class="d-flex flex-row">
                <!--begin::List-->
                <div class="flex-row-fluid d-block" id="kt_inbox_list">
                    <!--begin::Card-->
                    <div class="card card-custom card-stretch">
                        <!--begin::Header-->
                        <div
                            class="card-header row row-marginless align-items-center flex-wrap py-2 mx-0 h-auto border-0 bg-secondary px-0">
                            <!--begin::Toolbar-->
                            <div
                                class="col-12 col-sm-6 col-xxl-2 order-2 order-xxl-1 d-flex flex-wrap align-items-center">
                                <div class="d-flex align-items-center mr-1 my-2">
                                    <span @click.prevent="reload();"
                                        class="btn btn-default btn-icon btn-sm btn-light-primary mr-2"
                                        data-toggle="tooltip" title="Aggiorna la lista">
                                        <i class="ki ki-refresh icon-1x"></i>
                                    </span>

                                    <!--begin::Sort Dropdown-->
                                    <b-dropdown id="sort-dropdown" size="sm" variant="link"
                                        toggle-class="custom-v-dropdown btn btn-default btn-icon btn-sm btn-light-primary mr-1 no-dropdown-icon"
                                        dropleft ref="sortDropdown">
                                        <template v-slot:button-content>
                                            <i class="flaticon2-console icon-1x"></i>
                                        </template>

                                        <b-dropdown-text><strong>Filtra per tipo</strong></b-dropdown-text>

                                        <b-dropdown-divider></b-dropdown-divider>
                                        <b-dropdown-form>
                                            <b-form-checkbox v-for="circulartype in circulartypes"
                                                v-model="filters.idcirculartype" :value="circulartype.id"
                                                :key="circulartype.id" class="mb-3">{{
                                                    circulartype.circulartype }}</b-form-checkbox>
                                        </b-dropdown-form>
                                        <b-dropdown-divider></b-dropdown-divider>

                                        <b-dropdown-text><strong>Filtra per categoria</strong></b-dropdown-text>

                                        <b-dropdown-divider></b-dropdown-divider>
                                        <b-dropdown-form>
                                            <b-form-checkbox v-for="circularcategory in circularcategories"
                                                v-model="filters.idcircularcategory" :value="circularcategory.id"
                                                :key="circularcategory.id" class="mb-3">{{
                                                    circularcategory.circularcategory }}</b-form-checkbox>
                                        </b-dropdown-form>
                                        <b-dropdown-divider></b-dropdown-divider>

                                        <b-dropdown-text><strong>Filtra per tag</strong></b-dropdown-text>

                                        <b-dropdown-divider></b-dropdown-divider>
                                        <b-dropdown-form>
                                            <b-form-checkbox v-for="tag in tags" v-model="filters.tags" :value="tag.id"
                                                :key="tag.id" class="mb-3">{{
                                                    tag.circulartag }}</b-form-checkbox>
                                        </b-dropdown-form>
                                        <b-dropdown-divider></b-dropdown-divider>

                                        <b-dropdown-text><strong>Ordina per</strong></b-dropdown-text>

                                        <b-dropdown-divider></b-dropdown-divider>

                                        <div class="navi navi-hover min-w-md-250px">
                                            <b-dropdown-text tag="div"
                                                :class="{ 'navi-item': (this.sortBy.field !== 'date' || this.sortBy.direction !== 'DESC'), 'navi-item-hover': (this.sortBy.field === 'date' && this.sortBy.direction === 'DESC') }">
                                                <a href="#" class="navi-link"
                                                    @click.prevent="sortFields('date', 'DESC');">
                                                    <i v-if="this.sortBy.field === 'date' && this.sortBy.direction === 'DESC'"
                                                        class="fas fa-check text-success mr-2"></i>
                                                    <span class="navi-text">Più Recenti</span>
                                                </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div"
                                                :class="{ 'navi-item': (this.sortBy.field !== 'date' || this.sortBy.direction !== 'ASC'), 'navi-item-hover': (this.sortBy.field === 'date' && this.sortBy.direction === 'ASC') }">
                                                <a href="#" class="navi-link"
                                                    @click.prevent="sortFields('date', 'ASC');">
                                                    <i v-if="this.sortBy.field === 'date' && this.sortBy.direction === 'ASC'"
                                                        class="fas fa-check text-success mr-2"></i>
                                                    <span class="navi-text">Meno Recenti</span>
                                                </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div"
                                                :class="{ 'navi-item': (this.sortBy.field !== 'title' || this.sortBy.direction !== 'ASC'), 'navi-item-hover': (this.sortBy.field === 'title' && this.sortBy.direction === 'ASC') }">
                                                <a href="#" class="navi-link"
                                                    @click.prevent="sortFields('title', 'ASC');">
                                                    <i v-if="this.sortBy.field === 'title' && this.sortBy.direction === 'ASC'"
                                                        class="fas fa-check text-success mr-2"></i>
                                                    <span class="navi-text">Titolo</span>
                                                </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div"
                                                :class="{ 'navi-item': (this.sortBy.field !== 'idcirculartype' || this.sortBy.direction !== 'ASC'), 'navi-item-hover': (this.sortBy.field === 'idcirculartype' && this.sortBy.direction === 'ASC') }">
                                                <a href="#" class="navi-link"
                                                    @click.prevent="sortFields('idcirculartype', 'ASC');">
                                                    <i v-if="this.sortBy.field === 'idcirculartype' && this.sortBy.direction === 'ASC'"
                                                        class="fas fa-check text-success mr-2"></i>
                                                    <span class="navi-text">Tipo</span>
                                                </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div"
                                                :class="{ 'navi-item': (this.sortBy.field !== 'idcircularcategory' || this.sortBy.direction !== 'ASC'), 'navi-item-hover': (this.sortBy.field === 'idcircularcategory' && this.sortBy.direction === 'ASC') }">
                                                <a href="#" class="navi-link"
                                                    @click.prevent="sortFields('idcircularcategory', 'ASC');">
                                                    <i v-if="this.sortBy.field === 'idcircularcategory' && this.sortBy.direction === 'ASC'"
                                                        class="fas fa-check text-success mr-2"></i>
                                                    <span class="navi-text">Categoria</span>
                                                </a>
                                            </b-dropdown-text>
                                        </div>
                                    </b-dropdown>
                                    <!--end::Sort Dropdown-->
                                </div>
                            </div>
                            <!--end::Toolbar-->
                            <!--begin::Search-->
                            <div class="col-xxl-6 d-flex order-1 order-xxl-2 align-items-center justify-content-center">
                                <div class="input-group input-group-lg input-group-solid my-2">
                                    <input type="text" class="form-control pl-4" placeholder="Cerca..."
                                        v-model="filters.q" />
                                    <div class="input-group-append">
                                        <span class="input-group-text pr-3">
                                            <span class="svg-icon svg-icon-lg">
                                                <!--begin::Svg Icon | path:assets/media/svg/icons/General/Search.svg-->
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                                                    height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <path
                                                            d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                                            fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                        <path
                                                            d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                                            fill="#000000" fill-rule="nonzero" />
                                                    </g>
                                                </svg>
                                                <!--end::Svg Icon-->
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!--end::Search-->
                            <!--begin::Pagination-->
                            <div
                                class="col-12 col-sm-6 col-xxl-4 order-2 order-xxl-3 d-flex align-items-center justify-content-sm-end text-right my-2">
                                <div class="d-flex flex-column">
                                    <div v-if="circularstotal" class="text-dark font-weight-bold mr-2"
                                        data-toggle="dropdown">{{ circularsfrom }}-{{
                                            circularsto }} di
                                        {{ circularstotal }}</div>

                                    <div class="d-flex align-items-center">
                                        <!--begin::Per Pagina Dropdown-->
                                        <div class="d-flex align-items-center mr-2" data-toggle="tooltip"
                                            title="Comunicazioni per pagina">
                                            <b-dropdown v-if="circularstotal" id="perpage-dropdown" size="sm"
                                                variant="link"
                                                toggle-class="custom-v-dropdown btn btn-default btn-icon btn-sm btn-light-primary mr-1 no-dropdown-icon"
                                                dropleft ref="perpageDropdown">
                                                <template v-slot:button-content>
                                                    <i class="flaticon2-document icon-1x"></i>
                                                </template>
                                                <div v-for="(numperpage, index) in circularsnumperpages"
                                                    v-if="numperpage.label !== 'Tutti'" :key="index"
                                                    class="navi navi-hover min-w-md-250px">
                                                    <b-dropdown-text tag="div"
                                                        :class="{ 'navi-item': numperpage.value !== perpage, 'navi-item-hover': numperpage.value === perpage }">
                                                        <a href="#" @click.prevent="changeNumPerPage(numperpage)"
                                                            class="navi-link">
                                                            <i v-if="numperpage.value === perpage"
                                                                class="fas fa-check text-success mr-2"></i>
                                                            <span class="navi-text">{{ numperpage.label }}{{
                                                                (numperpage.label
                                                                    === 'Tutti' ? '' : ' per
                                                                pagina' ) }}</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                </div>
                                            </b-dropdown>
                                        </div>
                                        <!--end::Per Pagina Dropdown-->
                                        <!--begin::Arrow Buttons-->
                                        <span v-if="circularstotal && current_page > 1"
                                            class="btn btn-default btn-icon btn-sm btn-light-primary mr-1"
                                            data-toggle="tooltip" title="Prima pagina" @click.prevent="changePage(1);">
                                            <i class="ki ki-bold-double-arrow-back icon-sm"></i>
                                        </span>
                                        <span v-if="circularstotal && current_page === 1"
                                            class="btn btn-default btn-icon btn-sm btn-light-primary mr-1 disabled"
                                            data-toggle="tooltip" title="Prima pagina">
                                            <i class="ki ki-bold-double-arrow-back icon-sm"></i>
                                        </span>
                                        <span v-if="circularstotal && current_page > 1"
                                            class="btn btn-default btn-icon btn-sm btn-light-primary mr-1"
                                            data-toggle="tooltip" title="Pagina precedente"
                                            @click.prevent="changePage(current_page - 1);">
                                            <i class="ki ki-bold-arrow-back icon-sm"></i>
                                        </span>
                                        <span v-if="circularstotal && current_page === 1"
                                            class="btn btn-default btn-icon btn-sm btn-light-primary mr-1 disabled"
                                            data-toggle="tooltip" title="Pagina precedente">
                                            <i class="ki ki-bold-arrow-back icon-sm"></i>
                                        </span>
                                        <span v-if="circularstotal && current_page < last_page"
                                            class="btn btn-default btn-icon btn-sm btn-light-primary mr-1"
                                            data-toggle="tooltip" title="Pagina seguente"
                                            @click.prevent="changePage(current_page + 1);">
                                            <i class="ki ki-bold-arrow-next icon-sm"></i>
                                        </span>
                                        <span v-if="circularstotal && current_page === last_page"
                                            class="btn btn-default btn-icon btn-sm btn-light-primary mr-1 disabled"
                                            data-toggle="tooltip" title="Pagina seguente">
                                            <i class="ki ki-bold-arrow-next icon-sm"></i>
                                        </span>
                                        <span v-if="circularstotal && current_page < last_page"
                                            class="btn btn-default btn-icon btn-sm btn-light-primary mr-1"
                                            data-toggle="tooltip" title="Ultima pagina"
                                            @click.prevent="changePage(last_page);">
                                            <i class="ki ki-bold-double-arrow-next icon-sm"></i>
                                        </span>
                                        <span v-if="circularstotal && current_page === last_page"
                                            class="btn btn-default btn-icon btn-sm btn-light-primary mr-1 disabled"
                                            data-toggle="tooltip" title="Ultima pagina">
                                            <i class="ki ki-bold-double-arrow-next icon-sm"></i>
                                        </span>
                                        <!--end::Arrow Buttons-->
                                    </div>
                                </div>
                            </div>
                            <!--end::Pagination-->
                        </div>
                        <!--end::Header-->
                        <!--begin::Body-->
                        <div class="card-body table-responsive p-0">
                            <!--begin::perfect-scrollbar-->
                            <perfect-scrollbar class="scroll"
                                style="min-height: 70vh; max-height: 70vh; position: relative;" ref="circularsscroll">
                                <SmartTable ref="circularstable" url="/dashboard-circulars/index" :is_dialog="false"
                                    base_url="/dashboard-circulars" base_path="/dashboard-circulars"
                                    :fixed_filters="getFixedFilters">
                                    <template v-slot:header>
                                        &nbsp;
                                    </template>

                                    <template v-slot:body="slotProps">
                                        <!--begin::Items-->
                                        <div class="list min-w-500px">
                                            <div v-if="!slotProps.values.items.length" class="text-center text-dark-75">
                                                Nessuna Comunicazione da visualizzare</div>

                                            <!--begin::Item-->
                                            <div v-for="(item, index) in slotProps.values.items" :key="item.id"
                                                v-if="item.id" class="list-item marked-unread">
                                                <div class="d-flex align-items-center pt-0 mx-3" data-inbox="message">
                                                    <div class="d-flex align-items-center min-w-100px">
                                                        <div class="d-flex flex-column mr-3 mt-2">
                                                            <button @click.prevent="togglecircular(item)"
                                                                :class="'btn ' + (circularVisible(item) ? 'btn-light-danger btn-hover-light-danger' : 'btn-light-success btn-hover-light-success text-dark-75') + ' btn-sm btn-shadow font-weight-bold'">
                                                                <span v-if="!circularVisible(item)"
                                                                    class="svg-icon svg-icon-md">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px" height="24px" viewBox="0 0 24 24"
                                                                        version="1.1">
                                                                        <g stroke="none" stroke-width="1" fill="none"
                                                                            fill-rule="evenodd">
                                                                            <rect x="0" y="0" width="24" height="24" />
                                                                            <path
                                                                                d="M6,2 L18,2 C18.5522847,2 19,2.44771525 19,3 L19,12 C19,12.5522847 18.5522847,13 18,13 L6,13 C5.44771525,13 5,12.5522847 5,12 L5,3 C5,2.44771525 5.44771525,2 6,2 Z M7.5,5 C7.22385763,5 7,5.22385763 7,5.5 C7,5.77614237 7.22385763,6 7.5,6 L13.5,6 C13.7761424,6 14,5.77614237 14,5.5 C14,5.22385763 13.7761424,5 13.5,5 L7.5,5 Z M7.5,7 C7.22385763,7 7,7.22385763 7,7.5 C7,7.77614237 7.22385763,8 7.5,8 L10.5,8 C10.7761424,8 11,7.77614237 11,7.5 C11,7.22385763 10.7761424,7 10.5,7 L7.5,7 Z"
                                                                                fill="#000000" opacity="0.3" />
                                                                            <path
                                                                                d="M3.79274528,6.57253826 L12,12.5 L20.2072547,6.57253826 C20.4311176,6.4108595 20.7436609,6.46126971 20.9053396,6.68513259 C20.9668779,6.77033951 21,6.87277228 21,6.97787787 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,6.97787787 C3,6.70173549 3.22385763,6.47787787 3.5,6.47787787 C3.60510559,6.47787787 3.70753836,6.51099993 3.79274528,6.57253826 Z"
                                                                                fill="#000000" />
                                                                        </g>
                                                                    </svg>
                                                                    <!--end::Svg Icon-->
                                                                </span>
                                                                <span v-if="circularVisible(item)"
                                                                    class="svg-icon svg-icon-md">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px" height="24px" viewBox="0 0 24 24"
                                                                        version="1.1">
                                                                        <g stroke="none" stroke-width="1" fill="none"
                                                                            fill-rule="evenodd">
                                                                            <rect x="0" y="0" width="24" height="24" />
                                                                            <path
                                                                                d="M15.9956071,6 L9,6 C7.34314575,6 6,7.34314575 6,9 L6,15.9956071 C4.70185442,15.9316381 4,15.1706419 4,13.8181818 L4,6.18181818 C4,4.76751186 4.76751186,4 6.18181818,4 L13.8181818,4 C15.1706419,4 15.9316381,4.70185442 15.9956071,6 Z"
                                                                                fill="#000000" fill-rule="nonzero"
                                                                                opacity="0.3" />
                                                                            <path
                                                                                d="M10.1818182,8 L17.8181818,8 C19.2324881,8 20,8.76751186 20,10.1818182 L20,17.8181818 C20,19.2324881 19.2324881,20 17.8181818,20 L10.1818182,20 C8.76751186,20 8,19.2324881 8,17.8181818 L8,10.1818182 C8,8.76751186 8.76751186,8 10.1818182,8 Z"
                                                                                fill="#000000" />
                                                                        </g>
                                                                    </svg>
                                                                    <!--end::Svg Icon-->
                                                                </span>
                                                                {{ circularVisible(item) ? 'Chiudi' : 'Visualizza' }}
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div class="flex-grow-1 mx-2">
                                                        <div class="d-flex flex-wrap align-items-center">
                                                            <span v-if="item.idcirculartype"
                                                                class="label font-weight-bold label-inline mr-1 mt-1"
                                                                :class="'label-' + (item.idcirculartype.color ? item.idcirculartype.color : 'light-primary')"
                                                                style="font-size: 1rem; padding: 1rem;">{{
                                                                    item.idcirculartype.circulartype }}</span><br>

                                                            <span v-if="item.idcircularcategory"
                                                                class="label font-weight-bold label-inline mr-1 mt-1"
                                                                :class="'label-' + (item.idcircularcategory.color ? item.idcircularcategory.color : 'light-primary')"
                                                                style="font-size: 1rem; padding: 1rem;">{{
                                                                    item.idcircularcategory.circularcategory }}</span>

                                                            <span v-for="tag in item.tags"
                                                                class="label font-weight-bold label-inline mr-1 mt-1"
                                                                :class="'label-' + (tag.color ? tag.color : 'light-info')"
                                                                style="font-size: 1rem; padding: 1rem;">{{
                                                                    tag.tag }}</span>
                                                        </div>
                                                    </div>

                                                    <div class="mx-2 mt-2 w-90px text-right text-nowrap font-weight-bolder"
                                                        v-html="item.date.replace(' ', '<br>')"></div>

                                                    <div class="d-flex align-items-center min-w-100px">
                                                        <div class="d-flex flex-column ml-2 mt-2">
                                                            <a :href="getApiUrl() + '/dashboard-circular/print/' + item.id + '/' + item.slug + '?token=' + token"
                                                                target="_blank"
                                                                class="btn btn-primary btn-sm btn-shadow font-weight-bold">
                                                                <i class="fas fa-print"></i> Stampa
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="mb-3 mx-3">
                                                    <div class="mt-2">
                                                        <span class="font-size-h6 mr-2 font-weight-bolder">{{ item.title
                                                            }}{{ circularVisible(item) ? '' : ' -' }}</span>
                                                        <span v-if="!circularVisible(item)" class="text-dark-75">{{
                                                            html2text(item) }}...</span>
                                                    </div>
                                                    <div v-if="circularVisible(item)"
                                                        class="text-dark-75 p-2 border border-secondary rounded message-opened mt-2"
                                                        v-html="item.description"></div>
                                                    <div v-if="item.attachments.length" class="mt-2">
                                                        <span
                                                            class="text-primary font-weight-bolder mr-2">Allegati:</span>
                                                        <span v-for="attachment in item.attachments"
                                                            class="label label-outline-primary font-weight-bold label-inline mr-1 mt-1"
                                                            style="height: 30px;">
                                                            <a :href="appendToken(attachment.url)" target="_blank"
                                                                class="text-primary font-size-lg"
                                                                :title="attachment.title">
                                                                <i v-if="['jpg', 'jpeg', 'png', 'bmp', 'gif', 'psd'].includes(attachment.filetype)"
                                                                    class="far fa-file-image text-primary font-size-md"></i>
                                                                <i v-if="attachment.filetype === 'pdf'"
                                                                    class="far fa-file-pdf text-primary font-size-md"></i>
                                                                {{ (attachment.title.length > 30) ?
                                                                    attachment.title.substring(0, 30) + '...' :
                                                                    attachment.title }}
                                                            </a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--end::Item-->
                                        </div>
                                        <!--end::Items-->
                                    </template>
                                </SmartTable>
                            </perfect-scrollbar>
                            <!--end::perfect-scrollbar-->
                        </div>
                        <!--end::Body-->
                    </div>
                    <!--end::Card-->
                </div>
                <!--end::Aside-->
            </div>
        </b-card-body>
    </b-card>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SmartTable from "@/view/components/SmartTable.vue";
import JwtService from "@/core/services/jwt.service";
import ApiService from "@/core/services/api.service";

export default {
    name: "circulars",

    data() {
        return {
            circulartypes: [],
            circularcategories: [],
            tags: [],

            circularsfrom: 0,
            circularsto: 0,
            circularstotal: 0,

            current_page: 1,
            last_page: null,

            perpage: 15,
            sortBy: { field: 'date', direction: 'DESC' },

            circularsnumperpages: [],

            filters: {
                idcirculartype: [],
                idcircularcategory: [],
                tags: [],
                q: '',
            },

            circularsShow: {},
        }
    },

    components: {
        SmartTable,
    },

    computed: {
        token() {
            return JwtService.getToken();
        },

        getFixedFilters() {
            let filters = [];
            if (typeof this.filters !== "undefined" && this.filters !== null) {
                if (typeof this.filters.idcirculartype !== "undefined" && this.filters.idcirculartype && this.filters.idcirculartype.length > 0) {
                    for (let i in this.filters.idcirculartype) {
                        filters.push({ 'name': 'idcirculartype[]', 'filter': this.filters.idcirculartype[i] });
                    }
                }

                if (typeof this.filters.idcircularcategory !== "undefined" && this.filters.idcircularcategory && this.filters.idcircularcategory.length > 0) {
                    for (let i in this.filters.idcircularcategory) {
                        filters.push({ 'name': 'idcircularcategory[]', 'filter': this.filters.idcircularcategory[i] });
                    }
                }

                if (typeof this.filters.tags !== "undefined" && this.filters.tags && this.filters.tags.length > 0) {
                    for (let i in this.filters.tags) {
                        filters.push({ 'name': 'tags[]', 'filter': this.filters.tags[i] });
                    }
                }

                if (typeof this.filters.q !== "undefined" && this.filters.q.length > 0) {
                    filters.push({ 'name': 'q', 'filter': this.filters.q });
                }

                return filters;
            }

            return null;
        }
    },

    created() {
        let url = '';

        url = '/dashboard-circulars/load-options?type=idcirculartype';

        ApiService.query(url)
            .then((response) => {
                this.circulartypes = response.data;
            })
            .catch((error) => {
                console.log(error);
            })

        url = '/dashboard-circulars/load-options?type=idcircularcategory';

        ApiService.query(url)
            .then((response) => {
                this.circularcategories = response.data;
            })
            .catch((error) => {
                console.log(error);
            })

        url = '/dashboard-circulars/load-options?type=tags';

        ApiService.query(url)
            .then((response) => {
                this.tags = response.data;
            })
            .catch((error) => {
                console.log(error);
            })
    },

    mounted() {
        this.mountWatches();
    },

    watch: {
        filters: {
            handler: function (val, oldVal) {
                let vm = this;

                setTimeout(function () {
                    if (typeof vm.$refs.circularstable !== 'undefined') {
                        vm.$refs.circularstable.refresh();
                    }
                }, 100);

                setTimeout(() => {
                    if (typeof vm.$refs.circularsscroll !== 'undefined') {
                        vm.$refs.circularsscroll.$el.scrollTop = 0;
                    }
                }, 600);
            },
            deep: true,
        },
    },

    methods: {
        getApiUrl() {
            return ApiService.getApiUrl();
        },

        appendToken(url) {
            if (url) {
                let parts = url.split('?');
                if (parts.length >= 2) {
                    return url += '&token=' + JwtService.getToken();
                }
                else {
                    return url += '?token=' + JwtService.getToken();
                }
            }

            return url;
        },

        changePage(page) {
            if (typeof this.$refs.circularstable !== 'undefined') {
                this.$refs.circularstable.current_page = page;
                this.reload();
            }
        },

        sortFields(field, direction) {
            this.sortBy = { field: field, direction: direction };
            this.$refs.sortDropdown.hide(true);

            if (typeof this.$refs.circularstable !== 'undefined') {
                for (let i in this.$refs.circularstable.fields) {
                    this.$set(this.$refs.circularstable.fields[i], 'ordering', false);

                    if ((this.$refs.circularstable.fields[i].name === field) && (this.$refs.circularstable.fields[i].sortable)) {
                        this.$set(this.$refs.circularstable.fields[i], 'ordering', true);

                        this.$refs.circularstable.sortBy = field;
                        this.$refs.circularstable.sortDir = direction;

                        this.$set(this.$refs.circularstable.fields[i], 'ordering_direction', this.$refs.circularstable.sortDir);
                    }
                    else {
                        this.$set(this.$refs.circularstable.fields[i], 'ordering_direction', '');
                    }
                }

                this.reload();
            }
        },

        mountWatches() {
            this.$watch(
                () => {
                    if (typeof this.$refs.circularstable !== "undefined" && this.$refs.circularstable.from !== null) {
                        return this.$refs.circularstable.from;
                    }

                    return 0;
                },
                (value) => {
                    this.circularsfrom = value;
                }
            );

            this.$watch(
                () => {
                    if (typeof this.$refs.circularstable !== "undefined" && this.$refs.circularstable.to !== null) {
                        return this.$refs.circularstable.to;
                    }

                    return 0;
                },
                (value) => {
                    this.circularsto = value;
                }
            );

            this.$watch(
                () => {
                    if (typeof this.$refs.circularstable !== "undefined" && this.$refs.circularstable.total !== null) {
                        return this.$refs.circularstable.total;
                    }

                    return 0;
                },
                (value) => {
                    this.circularstotal = value;
                }
            );

            this.$watch(
                () => {
                    if (typeof this.$refs.circularstable !== "undefined" && this.$refs.circularstable.current_page !== null) {
                        return this.$refs.circularstable.current_page;
                    }

                    return 0;
                },
                (value) => {
                    this.current_page = value;
                }
            );

            this.$watch(
                () => {
                    if (typeof this.$refs.circularstable !== "undefined" && this.$refs.circularstable.last_page !== null) {
                        return this.$refs.circularstable.last_page;
                    }

                    return 0;
                },
                (value) => {
                    this.last_page = value;
                }
            );

            this.$watch(
                () => {
                    if (typeof this.$refs.circularstable !== "undefined" && this.$refs.circularstable.num_per_page !== null) {
                        return this.$refs.circularstable.num_per_page;
                    }

                    return [];
                },
                (value) => {
                    this.circularsnumperpages = JSON.parse(JSON.stringify(value));
                }
            );
        },

        changeNumPerPage(numperpage) {
            this.perpage = numperpage.value;
            this.$refs.perpageDropdown.hide(true);

            if (typeof this.$refs.circularstable !== 'undefined') {
                this.$refs.circularstable.per_page = numperpage;

                this.reload();
            }
        },

        reload() {
            if (typeof this.$refs.circularstable !== 'undefined') {
                this.$refs.circularstable.reload();

                let vm = this;
                setTimeout(() => {
                    vm.$refs.circularsscroll.$el.scrollTop = 0;
                }, 500);
            }
        },

        html2text(item) {
            let text = '';

            let html = item.description;
            let div = document.createElement("div");
            div.innerHTML = html;

            text = div.textContent || div.innerText || "";

            return text.substring(0, 100) + '...';
        },

        togglecircular(item) {
            if (typeof this.circularsShow[item.id] === "undefined") {
                this.$set(this.circularsShow, item.id, true);
            }
            else {
                this.$set(this.circularsShow, item.id, !this.circularsShow[item.id]);
            }
        },

        circularVisible(item) {
            if (typeof this.circularsShow[item.id] === "undefined") {
                return false;
            }

            return this.circularsShow[item.id];
        },
    },
};
</script>

<style>
i {
    font-size: inherit;
    color: inherit;
}

.btn-group.dropleft>.btn.dropdown-toggle:before {
    display: none;
}

.btn-group.dropright>.btn.dropdown-toggle:before {
    display: none;
}

.dropdown-toggle.nav-link:after,
.dropdown-toggle.btn:after {
    display: none;
}

.card-header .checkbox>span {
    background-color: #FFFFFF;
    border: 2px solid #0066CC;
}

.list-item {
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
}

.list-item:hover {
    box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
    z-index: 2;
}

.list-item:hover {
    box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
    z-index: 2;
}

.list-item.marked-read {
    background-color: #F2F6FC;
}

.list-item.marked-unread {
    background-color: #FFFFFF;
}

.message-opened ul {
    list-style-position: inside;
}
</style>
