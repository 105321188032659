<template>
    <b-card no-body header-bg-variant="primary" header="Documenti Utili" header-text-variant="white" header-tag="h5"
        border-variant="primary">
        <b-card-body class="p-0">
            <div class="d-flex flex-row">
                <!--begin::List-->
                <div class="flex-row-fluid d-block" id="kt_inbox_list">
                    <!--begin::Card-->
                    <div class="card card-custom card-stretch">
                        <!--begin::Header-->
                        <div
                            class="card-header row row-marginless align-items-center flex-wrap py-2 mx-0 h-auto border-0 bg-secondary px-0">
                            <!--begin::Toolbar-->
                            <div
                                class="col-12 col-sm-6 col-xxl-2 order-2 order-xxl-1 d-flex flex-wrap align-items-center">
                                <div class="d-flex align-items-center mr-1 my-2">
                                    <span @click.prevent="reload();"
                                        class="btn btn-default btn-icon btn-sm btn-light-primary mr-2"
                                        data-toggle="tooltip" title="Aggiorna la lista">
                                        <i class="ki ki-refresh icon-1x"></i>
                                    </span>

                                    <!--begin::Filter Dropdown-->
                                    <b-dropdown id="sort-dropdown" size="sm" variant="link"
                                        toggle-class="custom-v-dropdown btn btn-default btn-icon btn-sm btn-light-primary mr-1 no-dropdown-icon"
                                        dropleft ref="sortDropdown">
                                        <template v-slot:button-content>
                                            <i class="flaticon2-console icon-1x"></i>
                                        </template>

                                        <b-dropdown-text><strong>Filtra per categoria</strong></b-dropdown-text>

                                        <b-dropdown-divider></b-dropdown-divider>
                                        <b-dropdown-form>
                                            <b-form-checkbox v-for="documentcategory in documentcategories"
                                                v-model="filters.iddocumentcategory" :value="documentcategory.id"
                                                :key="documentcategory.id" class="mb-3">{{
                                                    documentcategory.documentcategory }}</b-form-checkbox>
                                        </b-dropdown-form>
                                        <b-dropdown-divider></b-dropdown-divider>

                                        <b-dropdown-text><strong>Filtra per tipo</strong></b-dropdown-text>

                                        <b-dropdown-divider></b-dropdown-divider>
                                        <b-dropdown-form>
                                            <b-form-checkbox v-for="documenttype in documenttypes"
                                                v-model="filters.iddocumenttype" :value="documenttype.id"
                                                :key="documenttype.id" class="mb-3">{{
                                                    documenttype.documenttype }}</b-form-checkbox>
                                        </b-dropdown-form>
                                        <b-dropdown-divider></b-dropdown-divider>

                                        <b-dropdown-text><strong>Filtra per tag</strong></b-dropdown-text>

                                        <b-dropdown-divider></b-dropdown-divider>
                                        <b-dropdown-form>
                                            <b-form-checkbox v-for="tag in tags" v-model="filters.tags" :value="tag.id"
                                                :key="tag.id" class="mb-3">{{
                                                    tag.documenttag }}</b-form-checkbox>
                                        </b-dropdown-form>

                                        <b-dropdown-divider></b-dropdown-divider>

                                        <b-dropdown-text><strong>Ordina per</strong></b-dropdown-text>

                                        <b-dropdown-divider></b-dropdown-divider>

                                        <div class="navi navi-hover min-w-md-250px">
                                            <b-dropdown-text tag="div"
                                                :class="{ 'navi-item': (this.sortBy.field !== 'date' || this.sortBy.direction !== 'DESC'), 'navi-item-hover': (this.sortBy.field === 'date' && this.sortBy.direction === 'DESC') }">
                                                <a href="#" class="navi-link"
                                                    @click.prevent="sortFields('date', 'DESC');">
                                                    <i v-if="this.sortBy.field === 'date' && this.sortBy.direction === 'DESC'"
                                                        class="fas fa-check text-success mr-2"></i>
                                                    <span class="navi-text">Più Recenti</span>
                                                </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div"
                                                :class="{ 'navi-item': (this.sortBy.field !== 'date' || this.sortBy.direction !== 'ASC'), 'navi-item-hover': (this.sortBy.field === 'date' && this.sortBy.direction === 'ASC') }">
                                                <a href="#" class="navi-link"
                                                    @click.prevent="sortFields('date', 'ASC');">
                                                    <i v-if="this.sortBy.field === 'date' && this.sortBy.direction === 'ASC'"
                                                        class="fas fa-check text-success mr-2"></i>
                                                    <span class="navi-text">Meno Recenti</span>
                                                </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div"
                                                :class="{ 'navi-item': (this.sortBy.field !== 'title' || this.sortBy.direction !== 'ASC'), 'navi-item-hover': (this.sortBy.field === 'title' && this.sortBy.direction === 'ASC') }">
                                                <a href="#" class="navi-link"
                                                    @click.prevent="sortFields('title', 'ASC');">
                                                    <i v-if="this.sortBy.field === 'title' && this.sortBy.direction === 'ASC'"
                                                        class="fas fa-check text-success mr-2"></i>
                                                    <span class="navi-text">Titolo</span>
                                                </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div"
                                                :class="{ 'navi-item': (this.sortBy.field !== 'iddocumentcategory' || this.sortBy.direction !== 'ASC'), 'navi-item-hover': (this.sortBy.field === 'iddocumentcategory' && this.sortBy.direction === 'ASC') }">
                                                <a href="#" class="navi-link"
                                                    @click.prevent="sortFields('iddocumentcategory', 'ASC');">
                                                    <i v-if="this.sortBy.field === 'iddocumentcategory' && this.sortBy.direction === 'ASC'"
                                                        class="fas fa-check text-success mr-2"></i>
                                                    <span class="navi-text">Categoria</span>
                                                </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div"
                                                :class="{ 'navi-item': (this.sortBy.field !== 'iddocumenttype' || this.sortBy.direction !== 'ASC'), 'navi-item-hover': (this.sortBy.field === 'iddocumenttype' && this.sortBy.direction === 'ASC') }">
                                                <a href="#" class="navi-link"
                                                    @click.prevent="sortFields('iddocumenttype', 'ASC');">
                                                    <i v-if="this.sortBy.field === 'iddocumenttype' && this.sortBy.direction === 'ASC'"
                                                        class="fas fa-check text-success mr-2"></i>
                                                    <span class="navi-text">Tipo</span>
                                                </a>
                                            </b-dropdown-text>
                                        </div>
                                    </b-dropdown>
                                    <!--end::Filter Dropdown-->
                                </div>
                            </div>
                            <!--end::Toolbar-->
                            <!--begin::Search-->
                            <div class="col-xxl-6 d-flex order-1 order-xxl-2 align-items-center justify-content-center">
                                <div class="input-group input-group-lg input-group-solid my-2">
                                    <input type="text" class="form-control pl-4" placeholder="Cerca..."
                                        v-model="filters.q" />
                                    <div class="input-group-append">
                                        <span class="input-group-text pr-3">
                                            <span class="svg-icon svg-icon-lg">
                                                <!--begin::Svg Icon | path:assets/media/svg/icons/General/Search.svg-->
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                                                    height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <path
                                                            d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                                            fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                        <path
                                                            d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                                            fill="#000000" fill-rule="nonzero" />
                                                    </g>
                                                </svg>
                                                <!--end::Svg Icon-->
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!--end::Search-->
                            <!--begin::Pagination-->
                            <div
                                class="col-12 col-sm-6 col-xxl-4 order-2 order-xxl-3 d-flex align-items-center justify-content-sm-end text-right my-2">
                                <div class="d-flex flex-column">
                                    <div v-if="documentstotal" class="text-dark font-weight-bold mr-2"
                                        data-toggle="dropdown">{{ documentsfrom }}-{{
                                            documentsto }} di
                                        {{ documentstotal }}</div>

                                    <div class="d-flex align-items-center">
                                        <!--begin::Per Pagina Dropdown-->
                                        <div class="d-flex align-items-center mr-2" data-toggle="tooltip"
                                            title="Documenti per pagina">
                                            <b-dropdown v-if="documentstotal" id="perpage-dropdown" size="sm"
                                                variant="link"
                                                toggle-class="custom-v-dropdown btn btn-default btn-icon btn-sm btn-light-primary mr-1 no-dropdown-icon"
                                                dropleft ref="perpageDropdown">
                                                <template v-slot:button-content>
                                                    <i class="flaticon2-document icon-1x"></i>
                                                </template>
                                                <div v-for="(numperpage, index) in documentsnumperpages"
                                                    v-if="numperpage.label !== 'Tutti'" :key="index"
                                                    class="navi navi-hover min-w-md-250px">
                                                    <b-dropdown-text tag="div"
                                                        :class="{ 'navi-item': numperpage.value !== perpage, 'navi-item-hover': numperpage.value === perpage }">
                                                        <a href="#" @click.prevent="changeNumPerPage(numperpage)"
                                                            class="navi-link">
                                                            <i v-if="numperpage.value === perpage"
                                                                class="fas fa-check text-success mr-2"></i>
                                                            <span class="navi-text">{{ numperpage.label }}{{
                                                                (numperpage.label
                                                                    === 'Tutti' ? '' : ' per
                                                                pagina' ) }}</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                </div>
                                            </b-dropdown>
                                        </div>
                                        <!--end::Per Pagina Dropdown-->
                                        <!--begin::Arrow Buttons-->
                                        <span v-if="documentstotal && current_page > 1"
                                            class="btn btn-default btn-icon btn-sm btn-light-primary mr-1"
                                            data-toggle="tooltip" title="Prima pagina" @click.prevent="changePage(1);">
                                            <i class="ki ki-bold-double-arrow-back icon-sm"></i>
                                        </span>
                                        <span v-if="documentstotal && current_page === 1"
                                            class="btn btn-default btn-icon btn-sm btn-light-primary mr-1 disabled"
                                            data-toggle="tooltip" title="Prima pagina">
                                            <i class="ki ki-bold-double-arrow-back icon-sm"></i>
                                        </span>
                                        <span v-if="documentstotal && current_page > 1"
                                            class="btn btn-default btn-icon btn-sm btn-light-primary mr-1"
                                            data-toggle="tooltip" title="Pagina precedente"
                                            @click.prevent="changePage(current_page - 1);">
                                            <i class="ki ki-bold-arrow-back icon-sm"></i>
                                        </span>
                                        <span v-if="documentstotal && current_page === 1"
                                            class="btn btn-default btn-icon btn-sm btn-light-primary mr-1 disabled"
                                            data-toggle="tooltip" title="Pagina precedente">
                                            <i class="ki ki-bold-arrow-back icon-sm"></i>
                                        </span>
                                        <span v-if="documentstotal && current_page < last_page"
                                            class="btn btn-default btn-icon btn-sm btn-light-primary mr-1"
                                            data-toggle="tooltip" title="Pagina seguente"
                                            @click.prevent="changePage(current_page + 1);">
                                            <i class="ki ki-bold-arrow-next icon-sm"></i>
                                        </span>
                                        <span v-if="documentstotal && current_page === last_page"
                                            class="btn btn-default btn-icon btn-sm btn-light-primary mr-1 disabled"
                                            data-toggle="tooltip" title="Pagina seguente">
                                            <i class="ki ki-bold-arrow-next icon-sm"></i>
                                        </span>
                                        <span v-if="documentstotal && current_page < last_page"
                                            class="btn btn-default btn-icon btn-sm btn-light-primary mr-1"
                                            data-toggle="tooltip" title="Ultima pagina"
                                            @click.prevent="changePage(last_page);">
                                            <i class="ki ki-bold-double-arrow-next icon-sm"></i>
                                        </span>
                                        <span v-if="documentstotal && current_page === last_page"
                                            class="btn btn-default btn-icon btn-sm btn-light-primary mr-1 disabled"
                                            data-toggle="tooltip" title="Ultima pagina">
                                            <i class="ki ki-bold-double-arrow-next icon-sm"></i>
                                        </span>
                                        <!--end::Arrow Buttons-->
                                    </div>
                                </div>
                            </div>
                            <!--end::Pagination-->
                        </div>
                        <!--end::Header-->

                        <SmartTable ref="documentstable" url="/dashboard-documents/index" :is_dialog="false"
                            base_url="/dashboard-documents" base_path="/dashboard-documents"
                            :fixed_filters="getFixedFilters">
                            <template v-slot:header>
                                <div></div>
                            </template>

                            <template v-slot:body="slotProps">
                                <!--begin::Body-->
                                <div v-if="!slotProps.values.items.length" class="text-center text-dark-75 my-3">Nessun
                                    documento da visualizzare</div>

                                <perfect-scrollbar v-if="slotProps.values.items.length" class="scroll"
                                    style="min-height: 70vh; max-height: 70vh; position: relative;"
                                    ref="documentsscroll">
                                    <table
                                        class="table table-hover table-head-custom table-vertical-center table-head-bg table-bordered">
                                        <thead>
                                            <tr class="text-left">
                                                <th class="bg-primary">
                                                    <a @click.prevent="sortFields('date', (sortBy.field === 'date' ? (sortBy.direction === 'ASC' ? 'DESC' : 'ASC') : 'ASC')); $event.target.blur();"
                                                        href=" javascript:void(0);"
                                                        class="text-white text-hover-white">DATA
                                                        <i v-if="sortBy.field === 'date' && sortBy.direction === 'ASC'"
                                                            class="flaticon2-arrow-up text-white"
                                                            style="font-size: 0.7rem;"></i>
                                                        <i v-if="sortBy.field === 'date' && sortBy.direction === 'DESC'"
                                                            class="flaticon2-arrow-down text-white"
                                                            style="font-size: 0.7rem;"></i>
                                                    </a>
                                                </th>

                                                <th class="bg-primary">
                                                    <a @click.prevent="sortFields('iddocumentcategory', (sortBy.field === 'iddocumentcategory' ? (sortBy.direction === 'ASC' ? 'DESC' : 'ASC') : 'ASC')); $event.target.blur();"
                                                        href=" javascript:void(0);"
                                                        class="text-white text-hover-white">CATEGORIA DOCUMENTO
                                                        <i v-if="sortBy.field === 'iddocumentcategory' && sortBy.direction === 'ASC'"
                                                            class="flaticon2-arrow-up text-white"
                                                            style="font-size: 0.7rem;"></i>
                                                        <i v-if="sortBy.field === 'iddocumentcategory' && sortBy.direction === 'DESC'"
                                                            class="flaticon2-arrow-down text-white"
                                                            style="font-size: 0.7rem;"></i>
                                                    </a>
                                                </th>

                                                <th class="bg-primary">
                                                    <a @click.prevent="sortFields('iddocumenttype', (sortBy.field === 'iddocumenttype' ? (sortBy.direction === 'ASC' ? 'DESC' : 'ASC') : 'ASC')); $event.target.blur();"
                                                        href=" javascript:void(0);"
                                                        class="text-white text-hover-white">TIPO
                                                        DOCUMENTO
                                                        <i v-if="sortBy.field === 'iddocumenttype' && sortBy.direction === 'ASC'"
                                                            class="flaticon2-arrow-up text-white"
                                                            style="font-size: 0.7rem;"></i>
                                                        <i v-if="sortBy.field === 'iddocumenttype' && sortBy.direction === 'DESC'"
                                                            class="flaticon2-arrow-down text-white"
                                                            style="font-size: 0.7rem;"></i>
                                                    </a>
                                                </th>

                                                <th class="bg-primary">
                                                    <a @click.prevent="sortFields('title', (sortBy.field === 'title' ? (sortBy.direction === 'ASC' ? 'DESC' : 'ASC') : 'ASC')); $event.target.blur();"
                                                        href=" javascript:void(0);"
                                                        class="text-white text-hover-white">ALLEGATO
                                                        <i v-if="sortBy.field === 'title' && sortBy.direction === 'ASC'"
                                                            class="flaticon2-arrow-up text-white"
                                                            style="font-size: 0.7rem;"></i>
                                                        <i v-if="sortBy.field === 'title' && sortBy.direction === 'DESC'"
                                                            class="flaticon2-arrow-down text-white"
                                                            style="font-size: 0.7rem;"></i>
                                                    </a>
                                                </th>

                                                <th class="bg-primary text-center"><span
                                                        class="text-white">SCARICA</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr :key="index" v-for="(item, index) in slotProps.values.items">
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        {{ item.date }}
                                                    </div>
                                                </td>

                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <span v-if="item.iddocumentcategory"
                                                            class="label font-weight-bold label-inline mr-1 mt-1"
                                                            :class="'label-' + (item.iddocumentcategory.color ? item.iddocumentcategory.color : 'light-primary')"
                                                            style="font-size: 1rem; padding: .3rem 1rem; height: auto !important;">{{
                                                                item.iddocumentcategory.documentcategory }}</span>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <span v-if="item.iddocumenttype"
                                                            class="label font-weight-bold label-inline mr-1 mt-1"
                                                            :class="'label-' + (item.iddocumenttype.color ? item.iddocumenttype.color : 'light-primary')"
                                                            style="font-size: 1rem; padding: .3rem 1rem; height: auto !important;">{{
                                                                item.iddocumenttype.documenttype }}</span>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <span class="font-size-lg mr-2 font-weight-bolder">{{
                                                            item.title }}</span>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div class="d-flex align-items-center justify-content-center">
                                                        <a v-if="item.documenturl" :href="appendToken(item.documenturl)"
                                                            target="_blank"><i
                                                                class="fas fa-cloud-download-alt text-primary"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </perfect-scrollbar>
                            </template>
                        </SmartTable>
                    </div>
                    <!--end::Card-->
                </div>
                <!--end::Aside-->
            </div>
        </b-card-body>
    </b-card>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SmartTable from "@/view/components/SmartTable.vue";
import JwtService from "@/core/services/jwt.service";
import ApiService from "@/core/services/api.service";

export default {
    name: "documents",

    data() {
        return {
            documenttypes: [],
            documentcategories: [],
            tags: [],

            documentsfrom: 0,
            documentsto: 0,
            documentstotal: 0,

            current_page: 1,
            last_page: null,

            perpage: 15,
            sortBy: { field: 'date', direction: 'DESC' },

            documentsnumperpages: [],

            filters: {
                iddocumenttype: [],
                iddocumentcategory: [],
                tags: [],
                q: '',
            },
        }
    },

    components: {
        SmartTable,
    },

    computed: {
        getFixedFilters() {
            let filters = [];
            if (typeof this.filters !== "undefined" && this.filters !== null) {
                if (typeof this.filters.iddocumenttype !== "undefined" && this.filters.iddocumenttype && this.filters.iddocumenttype.length > 0) {
                    for (let i in this.filters.iddocumenttype) {
                        filters.push({ 'name': 'iddocumenttype[]', 'filter': this.filters.iddocumenttype[i] });
                    }
                }

                if (typeof this.filters.iddocumentcategory !== "undefined" && this.filters.iddocumentcategory && this.filters.iddocumentcategory.length > 0) {
                    for (let i in this.filters.iddocumentcategory) {
                        filters.push({ 'name': 'iddocumentcategory[]', 'filter': this.filters.iddocumentcategory[i] });
                    }
                }

                if (typeof this.filters.tags !== "undefined" && this.filters.tags && this.filters.tags.length > 0) {
                    for (let i in this.filters.tags) {
                        filters.push({ 'name': 'tags[]', 'filter': this.filters.tags[i] });
                    }
                }

                if (typeof this.filters.q !== "undefined" && this.filters.q.length > 0) {
                    filters.push({ 'name': 'q', 'filter': this.filters.q });
                }

                return filters;
            }

            return null;
        }
    },

    created() {
        let url = '';

        url = '/dashboard-documents/load-options?type=iddocumenttype';

        ApiService.query(url)
            .then((response) => {
                this.documenttypes = response.data;
            })
            .catch((error) => {
                console.log(error);
            })

        url = '/dashboard-documents/load-options?type=iddocumentcategory';

        ApiService.query(url)
            .then((response) => {
                this.documentcategories = response.data;
            })
            .catch((error) => {
                console.log(error);
            })

        url = '/dashboard-documents/load-options?type=tags';

        ApiService.query(url)
            .then((response) => {
                this.tags = response.data;
            })
            .catch((error) => {
                console.log(error);
            })
    },

    mounted() {
        this.mountWatches();
    },

    watch: {
        filters: {
            handler: function (val, oldVal) {
                let vm = this;

                setTimeout(function () {
                    if (typeof vm.$refs.documentstable !== 'undefined') {
                        vm.$refs.documentstable.refresh();
                    }
                }, 100);

                setTimeout(() => {
                    if (typeof vm.$refs.documentsscroll !== 'undefined') {
                        vm.$refs.documentsscroll.$el.scrollTop = 0;
                    }
                }, 600);
            },
            deep: true,
        },
    },

    methods: {
        appendToken(url) {
            if (url) {
                let parts = url.split('?');
                if (parts.length >= 2) {
                    return url += '&token=' + JwtService.getToken();
                }
                else {
                    return url += '?token=' + JwtService.getToken();
                }
            }

            return url;
        },

        changePage(page) {
            if (typeof this.$refs.documentstable !== 'undefined') {
                this.$refs.documentstable.current_page = page;
                this.reload();
            }
        },

        sortFields(field, direction) {
            this.sortBy = { field: field, direction: direction };
            this.$refs.sortDropdown.hide(true);

            if (typeof this.$refs.documentstable !== 'undefined') {
                for (let i in this.$refs.documentstable.fields) {
                    this.$set(this.$refs.documentstable.fields[i], 'ordering', false);

                    if ((this.$refs.documentstable.fields[i].name === field) && (this.$refs.documentstable.fields[i].sortable)) {
                        this.$set(this.$refs.documentstable.fields[i], 'ordering', true);

                        this.$refs.documentstable.sortBy = field;
                        this.$refs.documentstable.sortDir = direction;

                        this.$set(this.$refs.documentstable.fields[i], 'ordering_direction', this.$refs.documentstable.sortDir);
                    }
                    else {
                        this.$set(this.$refs.documentstable.fields[i], 'ordering_direction', '');
                    }
                }

                this.reload();
            }
        },

        mountWatches() {
            this.$watch(
                () => {
                    if (typeof this.$refs.documentstable !== "undefined" && this.$refs.documentstable.from !== null) {
                        return this.$refs.documentstable.from;
                    }

                    return 0;
                },
                (value) => {
                    this.documentsfrom = value;
                }
            );

            this.$watch(
                () => {
                    if (typeof this.$refs.documentstable !== "undefined" && this.$refs.documentstable.to !== null) {
                        return this.$refs.documentstable.to;
                    }

                    return 0;
                },
                (value) => {
                    this.documentsto = value;
                }
            );

            this.$watch(
                () => {
                    if (typeof this.$refs.documentstable !== "undefined" && this.$refs.documentstable.total !== null) {
                        return this.$refs.documentstable.total;
                    }

                    return 0;
                },
                (value) => {
                    this.documentstotal = value;
                }
            );

            this.$watch(
                () => {
                    if (typeof this.$refs.documentstable !== "undefined" && this.$refs.documentstable.current_page !== null) {
                        return this.$refs.documentstable.current_page;
                    }

                    return 0;
                },
                (value) => {
                    this.current_page = value;
                }
            );

            this.$watch(
                () => {
                    if (typeof this.$refs.documentstable !== "undefined" && this.$refs.documentstable.last_page !== null) {
                        return this.$refs.documentstable.last_page;
                    }

                    return 0;
                },
                (value) => {
                    this.last_page = value;
                }
            );

            this.$watch(
                () => {
                    if (typeof this.$refs.documentstable !== "undefined" && this.$refs.documentstable.num_per_page !== null) {
                        return this.$refs.documentstable.num_per_page;
                    }

                    return [];
                },
                (value) => {
                    this.documentsnumperpages = JSON.parse(JSON.stringify(value));
                }
            );
        },

        changeNumPerPage(numperpage) {
            this.perpage = numperpage.value;
            this.$refs.perpageDropdown.hide(true);

            if (typeof this.$refs.documentstable !== 'undefined') {
                this.$refs.documentstable.per_page = numperpage;

                this.reload();
            }
        },

        reload() {
            if (typeof this.$refs.documentstable !== 'undefined') {
                this.$refs.documentstable.reload();

                let vm = this;
                setTimeout(() => {
                    if (typeof vm.$refs.documentsscroll !== 'undefined') {
                        vm.$refs.documentsscroll.$el.scrollTop = 0;
                    }
                }, 500);
            }
        },
    },
};
</script>

<style>
.btn-group.dropleft>.btn.dropdown-toggle:before {
    display: none;
}

.btn-group.dropright>.btn.dropdown-toggle:before {
    display: none;
}

.dropdown-toggle.nav-link:after,
.dropdown-toggle.btn:after {
    display: none;
}

.card-header .checkbox>span {
    background-color: #FFFFFF;
    border: 2px solid #0066CC;
}

.list-item {
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
}

.list-item:hover {
    box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
    z-index: 2;
}

.list-item:hover {
    box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
    z-index: 2;
}

.list-item.marked-read {
    background-color: #F2F6FC;
}

.list-item.marked-unread {
    background-color: #FFFFFF;
}

.message-opened ul {
    list-style-position: inside;
}
</style>
