<template>
    <b-modal ref="lastmessage" size="xl" hide-footer scrollable centered title="Avviso"
        :hide-header-close="true" :no-close-on-backdrop="true" :no-close-on-esc="true">
        <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
            <div class="d-flex flex-wrap justify-content-between">
                <a v-if="lastmessage.messagereadflag && !lastmessage.readflag" href="#"
                    @click.prevent="markReadMessage" class="mb-4 btn btn-primary font-weight-bolder mr-2">
                    <i class="fas fa-check icon-sm"></i> Confermo di aver preso visione
                </a>

                <a v-if="lastmessage.messagereadflag && lastmessage.readflag" href="javascript:void(0);"
                    class="mb-4 btn btn-secondary font-weight-bolder mr-2">
                    <i class="fas fa-check icon-sm"></i> Confermo di aver preso visione
                </a>

                <a v-if="!lastmessage.messagereadflag && !lastmessage.readflag" href="#"
                    @click.prevent="markReadMessage" class="mb-4 btn btn-primary font-weight-bolder mr-2">
                    <i class="fas fa-check icon-sm"></i> Segna come letto
                </a>

                <a v-if="!lastmessage.messagereadflag && lastmessage.readflag" href="javascript:void(0);"
                    class="mb-4 btn btn-secondary font-weight-bolder mr-2">
                    <i class="fas fa-check icon-sm"></i> Segna come letto
                </a>

                <a v-if="!lastmessage.mustreadflag || lastmessage.readflag" href="#"
                    @click.prevent="closeLastMessage" class="mb-4 btn btn-danger font-weight-bolder">
                    <i class="fas fa-window-close"></i> Chiudi
                </a>

                <a v-if="lastmessage.mustreadflag && !lastmessage.readflag" href="javascript:void(0);"
                    class="mb-4 btn btn-secondary font-weight-bolder">
                    <i class="fas fa-window-close"></i> Chiudi
                </a>
            </div>
            <h5>{{ lastmessage.date }}</h5>
            <h3>{{ lastmessage.title }}</h3>
            <p v-html="lastmessage.body"></p>
            <div class="d-flex flex-wrap justify-content-between">
                <a v-if="lastmessage.messagereadflag && !lastmessage.readflag" href="#"
                    @click.prevent="markReadMessage" class="mt-2 btn btn-primary font-weight-bolder mr-2">
                    <i class="fas fa-check icon-sm"></i> Confermo di aver preso visione
                </a>

                <a v-if="lastmessage.messagereadflag && lastmessage.readflag" href="javascript:void(0);"
                    class="mt-2 btn btn-secondary font-weight-bolder mr-2">
                    <i class="fas fa-check icon-sm"></i> Confermo di aver preso visione
                </a>

                <a v-if="!lastmessage.messagereadflag && !lastmessage.readflag" href="#"
                    @click.prevent="markReadMessage" class="mt-2 btn btn-primary font-weight-bolder mr-2">
                    <i class="fas fa-check icon-sm"></i> Segna come letto
                </a>

                <a v-if="!lastmessage.messagereadflag && lastmessage.readflag" href="javascript:void(0);"
                    class="mt-2 btn btn-secondary font-weight-bolder mr-2">
                    <i class="fas fa-check icon-sm"></i> Segna come letto
                </a>

                <a v-if="!lastmessage.mustreadflag || lastmessage.readflag" href="#"
                    @click.prevent="closeLastMessage" class="mt-2 btn btn-danger font-weight-bolder">
                    <i class="fas fa-window-close"></i> Chiudi
                </a>

                <a v-if="lastmessage.mustreadflag && !lastmessage.readflag" href="javascript:void(0);"
                    class="mt-2 btn btn-secondary font-weight-bolder">
                    <i class="fas fa-window-close"></i> Chiudi
                </a>
            </div>
        </b-overlay>
    </b-modal>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: 'LastMessage',

    data() {
        return {
            loading: false,

            lastmessage: {
                id: 0,
                date: '',
                title: '',
                body: '',
                messagereadflag: 0,
                mustreadflag: 0,
                readflag: 0,
                readdate: '',
            },
        };
    },

    created() {
        this.initPage();
    },

    methods: {
        initPage() {
            let url = '/get-last-message';

            this.loading = true;

            ApiService.query(url)
                .then((response) => {
                    if (response.data.status === 'OK') {
                        this.lastmessage = response.data.lastmessage;

                        let vm = this;
                        setTimeout(function () {
                            vm.$refs.lastmessage.show();
                        }, 200);
                    }

                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);

                    this.loading = false;
                })
        },

        closeLastMessage() {
            this.$refs.lastmessage.hide();
        },

        markReadMessage() {
            let url = '/mark-read-message?idmessage=' + this.lastmessage.id;

            this.loading = true;

            ApiService.query(url)
                .then((response) => {
                    if (response.data.status === 'OK') {
                        this.lastmessage.readflag = 1;
                    }

                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);

                    this.loading = false;
                })
        },
    },
};
</script>
