<template>
    <div class="mt-3">
        <div class="row" v-if="!isValidOperator()">
            <div class="col-md-8">
                <Welcome>
                </Welcome>
            </div>
        </div>

        <div class="mt-3" v-if="isValidOperator()">
            <div v-if="isSportingclub()" class="row mb-3">
                <div class="col-12 col-xxl-12">
                    <RsSportingclubSubscription></RsSportingclubSubscription>
                </div>
            </div>

            <div class="row">
                <div class="col-xxl-6">
                    <Welcome></Welcome>
                </div>

                <div class="col-xxl-6">
                    <Circulars></Circulars>
                    <div class="mt-3">
                        <Documents></Documents>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-xxl-12">
                    <MembercardsWidgets :customClass="'mt-3'"></MembercardsWidgets>
                </div>
            </div>
        </div>

        <LastMessage></LastMessage>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Welcome from "@/view/components/Welcome.vue";
import RsSportingclubSubscription from "@/view/components/RsSportingclubSubscription.vue";
import MembercardsWidgets from "@/view/components/MembercardsWidgets.vue";
import Documents from "@/view/components/Documents.vue";
import Circulars from "@/view/components/Circulars.vue";
import LastMessage from "@/view/components/LastMessage.vue";

export default {
    name: "Dashboard",

    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
        if (this.$store.getters.currentUser.usertype === 'Sodalizio' && this.$store.getters.currentUser.admin_sportingclubs.length === 0) {
            this.$router.push('/sportingclub/new');
        }
    },

    components: {
        Welcome,
        RsSportingclubSubscription,
        MembercardsWidgets,
        Documents,
        Circulars,
        LastMessage,
    },

    methods: {
        isSportingclub() {
            return ['Sodalizio'].includes(this.$store.getters.currentUser.usertype);
        },

        isValidOperator() {
            return ['Superadmin', 'Segreteria Nazionale', 'Comitato', 'Sodalizio'].includes(this.$store.getters.currentUser.usertype);
        },

        isSuperadmin() {
            return ['Superadmin'].includes(this.$store.getters.currentUser.usertype);
        },
    },
};
</script>
