var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm","opacity":"0.50","blur":"blur","spinner-variant":"primary"}},[(_vm.loaded && (typeof _vm.rssubscription !== 'undefined') && (['Superadmin', 'Segreteria Nazionale', 'Sodalizio'].includes(_vm.$store.getters.currentUser.usertype)))?_c('div',{staticClass:"alert d-flex justify-content-between align-items-center",class:{
            'alert-primary': [
                'In completamento',
                'In convalida',
                'Integrazioni inviate',
                'Integrazioni inviate (dopo istruttoria)',
                'Presa in carico da un operatore',
                'Presa in carico da un operatore (dopo istruttoria)',
                'Convalidata in istruttoria' ].includes(_vm.rssubscription.rssubscriptionstatuses.status),

            'alert-warning': [
                'Richiesta di integrazione',
                'Richiesta di integrazione (dopo istruttoria)' ].includes(_vm.rssubscription.rssubscriptionstatuses.status),

            'alert-success': [
                'Domanda accolta' ].includes(_vm.rssubscription.rssubscriptionstatuses.status),

            'alert-danger': [
                'Iscrizione sospesa',
                'Iscrizione annullata',
                'Domanda rifiutata' ].includes(_vm.rssubscription.rssubscriptionstatuses.status),
        }},[_c('h2',[_c('small',[_vm._v("Stato Iscrizione Registro: ")]),_vm._v(_vm._s(_vm.rssubscription.rssubscriptionstatuses.status))]),(_vm.show_rssubscription)?_c('button',{staticClass:"btn btn-primary font-weight-bolder",on:{"click":function($event){_vm.show_rssubscription = false}}},[_c('i',{staticClass:"fas fa-minus text-white"}),_vm._v(" Nascondi Dettagli")]):_vm._e(),(!_vm.show_rssubscription)?_c('button',{staticClass:"btn btn-primary font-weight-bolder",on:{"click":function($event){_vm.show_rssubscription = true}}},[_c('i',{staticClass:"fas fa-plus text-white"}),_vm._v(" Mostra Dettagli")]):_vm._e()]):_vm._e(),(_vm.loaded && (typeof _vm.rssubscription !== 'undefined') && (['Superadmin', 'Segreteria Nazionale', 'Sodalizio'].includes(_vm.$store.getters.currentUser.usertype)) && _vm.show_rssubscription)?_c('div',{staticClass:"p-5 m-0 border border-primary rounded"},[_c('form',{staticClass:"form"},[_c('div',{staticClass:"card-body p-0 m-0"},[_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-md-12"},[_c('img',{attrs:{"src":"/media/logos/logo-registrosalute.png"}}),_c('br'),_c('label',{staticClass:"font-weight-bolder font-size-h3 d-inline-block"},[_vm._v("Data Aggiornamento:")]),(_vm.loaded)?_c('span',{staticClass:"d-inline-block ml-3 font-size-h3"},[_vm._v(_vm._s((_vm.rssubscription.date ? _vm.dateFromIsoToIt(_vm.rssubscription.date) : '-')))]):_vm._e()])]),(_vm.rssubscription.note)?_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"font-weight-bolder font-size-h3 d-inline-block"},[_vm._v("Note:")]),(_vm.loaded)?_c('div',{staticClass:"ml-3"},[_c('pre',{staticStyle:{"text-wrap":"wrap","font-size":"120%"}},[_vm._v(_vm._s(_vm.rssubscription.note))])]):_vm._e()])]):_vm._e()])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }