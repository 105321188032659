<template>
    <b-card no-body header-bg-variant="primary" header-text-variant="white" header-tag="h5" header="Stock Tessere"
        :class="customClass">
        <b-card-body class="p-0">
            <div v-if="['Superadmin', 'Segreteria Nazionale', 'Comitato'].includes($store.getters.currentUser.usertype)"
                class="row">
                <div class="col-md-12 p-7">
                    <div class="form-group">
                        <label for="idannuality" class="font-weight-bolder">Annualità:</label>
                        <select class="form-control" id="idannuality" v-model="idannuality">
                            <option v-for="annuality in annualities" :value="annuality.id">
                                {{ annuality.solarsannualitydisplay }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="table-responsive">
                <table class="table table-borderless table-vertical-center">
                    <tbody>
                        <template v-if="list.length" v-for="(item, i) in list">
                            <tr v-bind:key="i">
                                <td class="py-4" style="width: 50px; max-width: 70px;">
                                    <div :class="'symbol symbol-light-' + item.variant + ' symbol-50 mr-3'">
                                        <span class="symbol-label font-weight-bolder font-size-h5">{{
                                            item.text0 }}</span>
                                    </div>
                                </td>
                                <td class="px-0" style="width: 100px; max-width: 105px;">
                                    <a href="#"
                                        :class="'text-dark-75 font-weight-bolder text-hover-' + item.variant + ' mb-1 font-size-lg'">
                                        {{ item.text1 }}
                                    </a>
                                    <div>
                                        <a :class="'text-muted font-weight-bold text-hover-' + item.variant" href="#">
                                            {{ item.text2 }}
                                        </a>
                                    </div>
                                </td>
                                <td class="text-right" style="min-width: 100px;">
                                    <div>
                                        <div class="d-flex justify-content-between">
                                            <div class="text-black font-weight-bold">Richieste {{
                                                parseInt(item.value_richieste) }}</div>
                                            <div class="text-success font-weight-bold">
                                                Attribuite/Utilizzate {{
                                                    item.value_distribuite_utilizzate }}</div>
                                            <div class="text-warning font-weight-bold">Attribuite/Non
                                                Utilizzate {{ item.value_distribuite_nonutilizzate }}
                                            </div>
                                            <div v-if="$store.getters.currentUser.usertype !== 'Sodalizio'"
                                                class="text-info font-weight-bold">Rimanenti {{
                                                    item.value_rimanenti_richieste }}</div>
                                        </div>
                                    </div>
                                    <b-progress max="100" variant="primary">
                                        <b-progress-bar
                                            :value="(100 * item.value_distribuite_utilizzate / item.value_richieste)"
                                            variant="success"></b-progress-bar>
                                        <b-progress-bar
                                            :value="(100 * item.value_distribuite_nonutilizzate / item.value_richieste)"
                                            variant="warning"></b-progress-bar>
                                        <b-progress-bar v-if="$store.getters.currentUser.usertype !== 'Sodalizio'"
                                            :value="(100 * item.value_rimanenti_richieste / item.value_richieste)"
                                            variant="info"></b-progress-bar>
                                    </b-progress>
                                </td>
                            </tr>
                        </template>

                        <tr v-if="!list.length">
                            <td class="text-center font-weight-bolder">Nessuna informazione trovata in
                                archivio</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div v-if="['Superadmin', 'Segreteria Nazionale', 'Comitato'].includes($store.getters.currentUser.usertype)"
                class="text-center mb-4">
                <button class="btn btn-primary font-weight-bolder" @click="exportMembercardsUsage"><i
                        class="fas fa-file-export"></i> Esporta</button>
            </div>
        </b-card-body>
    </b-card>
</template>

<script>
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

export default {
    name: 'MembercardsWidgets',

    props: {
        customClass: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            loading: false,

            list: [],

            idannuality: '',
            annualities: [],
        };
    },

    created() {
        this.loading = true;

        if (['Superadmin', 'Segreteria Nazionale', 'Comitato'].includes(this.$store.getters.currentUser.usertype)) {
            let url = '/dashboard/get-annualities';

            ApiService.query(url)
                .then((response) => {
                    this.annualities = response.data;

                    if (this.annualities.length) {
                        if (!this.idannuality) {
                            this.idannuality = this.annualities[0].id;
                        }
                    }

                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);

                    this.loading = false;
                })
        }

        if (['Sodalizio'].includes(this.$store.getters.currentUser.usertype)) {
            this.updateStockMembercards();
        }
    },

    watch: {
        idannuality: function (value, oldValue) {
            this.updateStockMembercards();
        }
    },

    methods: {
        updateStockMembercards() {
            if (['Superadmin', 'Segreteria Nazionale', 'Comitato', 'Sodalizio'].includes(this.$store.getters.currentUser.usertype)) {
                let url = '';
                if (!this.idannuality) {
                    url = '/dashboard/get-membercards-usage';
                }
                else {
                    url = '/dashboard/get-membercards-usage?idannuality=' + this.idannuality;
                }

                this.loading = true;

                ApiService.query(url)
                    .then((response) => {
                        this.list = [];

                        if (response.data.status === 'OK') {
                            this.list = response.data.membercards_usage;
                        }

                        this.loading = false;
                    })
                    .catch((error) => {
                        console.log(error);

                        this.loading = false;
                    })
            }
        },

        exportMembercardsUsage() {
            let url = process.env.VUE_APP_API_ENDPOINT + '/dashboard/export-membercards-usage';

            url = this.appendToken(url);

            window.open(url);
        },

        appendToken(url) {
            if (url) {
                let parts = url.split('?');
                if (parts.length >= 2) {
                    return url += '&token=' + JwtService.getToken();
                }
                else {
                    return url += '?token=' + JwtService.getToken();
                }
            }

            return url;
        },
    },
};
</script>
