<template>
    <div class="card card-custom card-stretch">
        <div class="card-header row row-marginless align-items-center flex-wrap py-5 h-auto">
            <div class="card-title">
                <h3 class="card-label">Messaggi</h3>
            </div>
        </div>

        <div class="card-body table-responsive text-justify">
            <div>
                <h3 style="color: #0D5394; font-weight: bold; text-align: center;">
                    {{ values.title }}
                </h3>
                <h3 v-if="values.subtitle" style="color: #0D5394; font-weight: bold; text-align: center;">
                    <em>{{ values.subtitle }}</em>
                </h3>

                <p v-if="values.body" style="text-align: justify; margin-top: 30px;" v-html="values.body"></p>

                <ol v-if="values.attachments.length">
                    <li v-for="(attachment, index) in values.attachments" :key="attachment.id">
                        <a :href="appendToken(attachment.url)" target="_blank" class="text-primary font-size-lg"
                            :title="attachment.title">
                            {{ attachment.title }}
                        </a>
                    </li>
                </ol>

                <p v-if="values.footer" style="text-align: justify; margin-top: 30px;" v-html="values.footer"></p>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

export default {
    name: 'Welcome',

    data() {
        return {
            values: {
                title: '',
                subtitle: '',
                body: '',
                attachments: [],
                footer: '',
            }
        };
    },

    created() {
        let url = '';

        url = '/dashboard-message/load-message';

        ApiService.query(url)
            .then((response) => {
                this.values = response.data;
            })
            .catch((error) => {
                console.log(error);
            })
    },

    methods: {
        appendToken(url) {
            if (url) {
                let parts = url.split('?');
                if (parts.length >= 2) {
                    return url += '&token=' + JwtService.getToken();
                }
                else {
                    return url += '?token=' + JwtService.getToken();
                }
            }

            return url;
        },
    }
};
</script>
