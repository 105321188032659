<template>
    <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
        <div v-if="loaded && (typeof rssubscription !== 'undefined') && (['Superadmin', 'Segreteria Nazionale', 'Sodalizio'].includes($store.getters.currentUser.usertype))"
            class="alert d-flex justify-content-between align-items-center" :class="{
                'alert-primary': [
                    'In completamento',
                    'In convalida',
                    'Integrazioni inviate',
                    'Integrazioni inviate (dopo istruttoria)',
                    'Presa in carico da un operatore',
                    'Presa in carico da un operatore (dopo istruttoria)',
                    'Convalidata in istruttoria',
                ].includes(rssubscription.rssubscriptionstatuses.status),

                'alert-warning': [
                    'Richiesta di integrazione',
                    'Richiesta di integrazione (dopo istruttoria)',
                ].includes(rssubscription.rssubscriptionstatuses.status),

                'alert-success': [
                    'Domanda accolta',
                ].includes(rssubscription.rssubscriptionstatuses.status),

                'alert-danger': [
                    'Iscrizione sospesa',
                    'Iscrizione annullata',
                    'Domanda rifiutata',
                ].includes(rssubscription.rssubscriptionstatuses.status),
            }">
            <h2><small>Stato Iscrizione Registro: </small>{{ rssubscription.rssubscriptionstatuses.status
                }}</h2>
            <button v-if="show_rssubscription" @click="show_rssubscription = false"
                class="btn btn-primary font-weight-bolder"><i class="fas fa-minus text-white"></i> Nascondi
                Dettagli</button>
            <button v-if="!show_rssubscription" @click="show_rssubscription = true"
                class="btn btn-primary font-weight-bolder"><i class="fas fa-plus text-white"></i> Mostra
                Dettagli</button>
        </div>

        <div class="p-5 m-0 border border-primary rounded"
            v-if="loaded && (typeof rssubscription !== 'undefined') && (['Superadmin', 'Segreteria Nazionale', 'Sodalizio'].includes($store.getters.currentUser.usertype)) && show_rssubscription">
            <form class="form">
                <div class="card-body p-0 m-0">
                    <div class="form-group row">
                        <div class="col-md-12">
                            <img src="/media/logos/logo-registrosalute.png" /><br>
                            <label class="font-weight-bolder font-size-h3 d-inline-block">Data
                                Aggiornamento:</label>
                            <span v-if="loaded" class="d-inline-block ml-3 font-size-h3">{{
                                (rssubscription.date ? dateFromIsoToIt(rssubscription.date) : '-') }}</span>
                        </div>
                    </div>
                    <div v-if="rssubscription.note" class="form-group row">
                        <div class="col-md-12">
                            <label class="font-weight-bolder font-size-h3 d-inline-block">Note:</label>
                            <div v-if="loaded" class="ml-3">
                                <pre style="text-wrap: wrap; font-size: 120%;">{{ rssubscription.note }}</pre>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </b-overlay>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: "ManageRsSubscription",

    props: ['item'],

    data() {
        return {
            loading: false,
            loaded: false,
            show_rssubscription: false,
            rssubscription: {},
        };
    },

    created() {
        let url = '';

        if (this.$store.getters.currentUser.usertype === 'Superadmin' || this.$store.getters.currentUser.usertype === 'Segreteria Nazionale') {
            url = '/sportingclub/get-rs-subscription/' + this.item.idaffiliation;
        }

        if (this.$store.getters.currentUser.usertype === 'Sodalizio') {
            url = '/sportingclub/get-rs-subscription';
        }

        if (url) {
            this.loading = true;

            ApiService.query(url)
                .then((response) => {
                    if (response.data.status === 'OK') {
                        this.rssubscription = response.data.rssubscription;
                        this.loaded = true;

                        if (
                            (typeof this.item !== 'undefined')
                            &&
                            (typeof this.item.idaffiliation !== 'undefined')
                            &&
                            this.item.idaffiliation
                            &&
                            ['Superadmin', 'Segreteria Nazionale'].includes(this.$store.getters.currentUser.usertype)
                        ) {
                            this.show_rssubscription = true;
                        }
                    }

                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;

                    console.log(error);
                })
        }
    },

    computed: {
    },

    mounted() {
    },

    methods: {
        dateFromIsoToIt(isodate) {
            let date = new Date(isodate);

            let month = date.getMonth() + 1;
            if (month < 10) month = '0' + month;

            let day = date.getDate();
            if (day < 10) day = '0' + day;

            return day + '/' + month + '/' + date.getFullYear();
        },
    }
};
</script>

<style>
.border-bluenavy .card-header {
    border-bottom: 1px solid #143D67;
}
</style>
