<template>
    <div>
        <ManageRsSubscription></ManageRsSubscription>
    </div>
</template>

<script>
import ManageRsSubscription from "@/view/components/ManageRsSubscription.vue";

export default {
    name: "RsSportingclubSubscription",

    components: {
        ManageRsSubscription,
    },
};
</script>

<style>
.border-bluenavy .card-header {
    border-bottom: 1px solid #143D67;
}
</style>
